import React from 'react';

const Avatar = ({ src }) => {
    return (
        <div className="avatar avatar-md border mr-1" 
        style={{
        backgroundColor:"#eee",
        backgroundImage: "url('"+src+"')",
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundPosition: 'center center,center center', 
        backgroundSize:"40px",
        height:"40px",
        width:"40px",
        }}></div>
    );
};

export default Avatar;