import React, { Component, Fragment } from "react";
import {  Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { addItem} from "../../utils/api";
import {minLength} from './../../utils/Validation'
import LogoLink from "./../../components/mikro/LogoLink";
import toastr from "../../utils/toastr";
import { EyeIcon, EyeSlashIcon } from "../../components/mikro/Icon";

export default class Daftar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateTo: "",
      spinner: false,
      showPassword: false,
    showConfirm:false,
      
      nama_pic: "",
      nomor_hp: "",
      email: "",
      posisi: "",
      password: "",
      confirmed: "",

      errors: null,

      validity: 0,
    };
  }

  componentDidMount() {
  }
  componentWillUnmount(){
    this.setState({
      nama_pic: "",
      nomor_hp: "",
      email: "",
      posisi: "",
      password: "",
      confirmed: "",
      errors:null
    })
  }

  handleSubmit(e) {
    e.preventDefault();


    let errors = null
    const {
      nama_pic,
      nomor_hp,
      email,
      posisi,
      password,
      confirmed,
    } = this.state
    if (!minLength(nama_pic,5))  errors = {...errors,nama_pic : "Nama PIC minimal 5 karakter"}
    if (!minLength(nomor_hp,8))  errors = {...errors,nomor_hp : "Nomor HP minimal 8 karakter"}
    if (!minLength(email,5))  errors = {...errors,email : "Email PIC minimal 5 karakter"}
    if (!minLength(posisi,3))  errors = {...errors,posisi : "Posisi/Jabatan minimal 3 karakter"}

    if (!minLength(password,6))  errors = {...errors,password : "Password minimal 6 karakter"}
    if (!minLength(confirmed,6)) errors = {...errors,confirmed : "Password minimal 6 karakter"}

    if (password !== confirmed) errors = {...errors,confirmed : "Password tidak sama"};

    this.setState({ errors })

    if (errors === null)
      this.setState({ spinner: true },()=>{
        addItem("register", {
          nama_pic: nama_pic,
          nomor_hp: nomor_hp,
          email: email,
          posisi: posisi,
          password: password,
          confirmed: confirmed,
        }).then((res) => {
          if (res.code === 200) {
            toast.success("Selamat!, Anda telah berhasil registrasi");
            setTimeout(() => {
              this.setState({
                navigateTo: "/",
              });
            }, 3000);
          } else {
            toastr(res.status,res.message)
          }
          this.setState({
            spinner: false,
          });
        });
      });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const { nama_pic, nomor_hp, email, posisi, password, confirmed, spinner,errors, showPassword,showConfirm } = this.state;
    return (
      <Fragment>
        {this.state.navigateTo.length > 0 ? <Redirect to={this.state.navigateTo} /> : ""}
        <div className="container bg-white">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="w-100 mt-1 mb-3 text-center"> 
              <LogoLink width="200px" />
              </div>
              <h1
                className="text-center font-weight-bold"
                style={{ color: "#000" }}
              >Daftar Akun</h1>
              <p className="fs-16px text-center mb-3">
                Sudah punya akun?&nbsp;<Link className="card-link font-weight-bold" to="/">Login di sini</Link>
              </p>
              
              <form className="form container  text-left" onSubmit={(e) => this.handleSubmit(e)}>
                <div className="row">
                  <div className="col-lg-6  mb-2">
                    <label className="font-weight-bold">Nama PIC</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nama_pic"
                      value={nama_pic}
                      onChange={(e) => this.handleChange(e)}
                      required={true}
                      placeholder="Masukkan Nama PIC"
                      disabled={spinner}
                    />

                    {errors && errors.nama_pic && (
                      <div className="invalid-feedback d-block">
                        {errors.nama_pic}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6  mb-2">
                    <label className="font-weight-bold">No. HP Aktif</label>
                    <input
                      type="number"
                      className="form-control"
                      name="nomor_hp"
                      value={nomor_hp}
                      onChange={(e) => this.handleChange(e)}
                      required={true}
                      placeholder="Contoh: 082xxxxxxxxx"
                      disabled={spinner}
                    />
                    {errors && errors.nomor_hp && (
                      <div className="invalid-feedback d-block">
                        {errors.nomor_hp}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6  mb-2">
                    <label className="font-weight-bold">Email PIC</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => this.handleChange(e)}
                      required={true}
                      placeholder="Masukkan Email PIC"
                      disabled={spinner}
                    />
                    {errors && errors.email && (
                      <div className="invalid-feedback d-block">
                        {errors.email}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6  mb-2">
                    <label className="font-weight-bold">Posisi/Jabatan</label>
                    <input
                      type="text"
                      className="form-control"
                      name="posisi"
                      value={posisi}
                      onChange={(e) => this.handleChange(e)}
                      required={true}
                      placeholder="Masukkan posisi atau jabatan PIC"
                      disabled={spinner}
                    />
                    {errors && errors.posisi && (
                      <div className="invalid-feedback d-block">
                        {errors.posisi}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6  mb-2">
                    <label className="font-weight-bold">Password</label>
                    <div class="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control px-2 form-control-auth"
                        name="password"
                        value={password}
                        minLength="6"
                        maxLength="50"
                        onChange={(e) => this.handleChange(e)}
                        autoComplete="off"
                        placeholder="Masukkan password"
                        required
                        disabled={spinner}
                      />
                      <div
                        class="input-group-append bg-white pointer "
                        onClick={(e) => this.setState({ showPassword:!showPassword })}
                      >
                        <span class="input-group-text bg-white fs-16px  px-1-2">
                          {showPassword ? (
                            <EyeIcon/>
                          ) : (
                            <EyeSlashIcon/>
                          )}
                        </span>
                      </div>
                    </div>
                    {errors && errors.password && (
                      <div className="invalid-feedback d-block">
                        {errors.password}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6  mb-2">
                    <label className="font-weight-bold">Konfirmasi Password</label>
                    <div class="input-group">
                      <input
                        type={showConfirm ? "text" : "password"}
                        className="form-control px-2 form-control-auth"
                        name="confirmed"
                        value={confirmed}
                        minLength="6"
                        maxLength="50"
                        onChange={(e) => this.handleChange(e)}
                        autoComplete="off"
                        placeholder="Masukkan ulang password"
                        required
                        disabled={spinner}
                      />
                      <div
                        class="input-group-append bg-white pointer "
                        onClick={(e) => this.setState({ showConfirm:!showConfirm })}
                      >
                        <span class="input-group-text bg-white fs-16px  px-1-2">
                          {showConfirm ? (
                            <EyeIcon/>
                          ) : (
                            <EyeSlashIcon/>
                          )}
                        </span>
                      </div>
                    </div>
                    {errors && errors.confirmed && (
                      <div className="invalid-feedback d-block">
                        {errors.confirmed}
                      </div>
                    )}
                  </div>
                </div>
                <p class="fs-14px text-secondary">
                  Dengan mendaftar Anda telah menyetujui <Link to="/kebijakan-privasi" className="font-weight-bold">Syarat Ketentuan &amp; Kebijakan Privasi</Link>
                </p>
                <button
                  type="submit"
                  className="btn btn-warning btn-block py-1 my-2 fs-16px"
                  disabled={spinner}
                >
                  {spinner === true ? (
                    <span>Menyimpan...</span>
                  ) : (
                    <span>Daftar Sekarang</span>
                  )}
                </button>
              </form>
            </div>
            <div className="p-2 col-md-12"></div>
          </div>
        </div>
      </Fragment>
    );
  }
}
