import React, { Component } from "react";

import { Modal, Button } from "react-bootstrap";
import Pencarian from "../filter/PencarianInput";
import InfiniteScroll from "react-infinite-scroller";

import { getIndex } from "../../../utils/api";

export default class MultiSelectList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      search: "",
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
      hasMore: true,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.openModal !== this.props.openModal &&
      this.props.openModal === true
    ) {
      this.getIndex();
    }
  }
  getIndex() {
    const { current_page, search } = this.state;
    getIndex(this.props.url, {
      page: current_page + 1,
      search: search,
    }).then((res) => {
      if (res.code === 200) {
        let { items } = this.state;
        this.setState({
          items: items.concat(res.data),
          current_page: res.meta.current_page || 0,
          last_page: res.meta.last_page || 0,
          per_page: res.meta.per_page || 0,
          total: res.meta.total || 0,
          hasMore: res.data.length === res.meta.per_page ? true : false,
        });
      }
    });
  }

  handleChange(d, i) {
    let { items } = this.state;
    items[i].checked = !items[i].checked;
    this.setState({ items: items });
  }
  
  handleFilter() {
    this.props.onChange({
      name: this.props.name || "list",
      data: this.state.items.filter(v => v.checked === true),
    });
    this.props.onClose();
  }
  handleSearch(e) {
    this.setState({
      search: e.target.value,
    });
  }

  handleReset() {
    this.setState({
      items: [],
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
      hasMore: true,
    });
  }

  handleClose() {
    this.setState({ hasMore: false }, () => this.props.onClose());
  }

  render() {
    const { items, hasMore, current_page,search } = this.state;
    const { openModal } = this.props;

    const data = items.filter(v=>v.label.toLowerCase().indexOf(search.toLowerCase()) >= 0)
    return (
      <div>
        <Modal
          show={openModal}
          centered
          style={{ zIndex: "20000" }}
          backdropClassName="z-index-top"
          onHide={() => this.props.onClose()}
        >
          <Modal.Header>
            <Modal.Title className="text-center w-100">
              {this.props.title || "Pilih Opsi"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Header className="p-1">
            <div className="w-100">
              <Pencarian onChangeValue={(e) => this.handleSearch(e)} />
            </div>
          </Modal.Header>
          <Modal.Body
            style={{
              height: "50vh",
              overflowY: "auto",
              overflowX: "hidden",
              position: "relative",
              padding: 0,
            }}
          >
            <InfiniteScroll
                pageStart={0}
                loadMore={() => this.getIndex()}
                hasMore={hasMore}
                // loader={<Spinner />}
                useWindow={false}
              >
            <div
              className="bg-white mb-2 d-flex flex-column"
              style={{
                height: "100%",
              }}
            >
              
                <ul class="list-group m-0 p-0">
                  {data.map((d, i) => (
                    <li class="list-group-item rounded-0 pointer" onClick={()=>this.handleChange(d, i)} key={i}>
                      <div class="form-check">
                        <input 
                          className="form-check-input pointer mr-2" 
                          type="checkbox" 
                          name="optionsradio" 
                          id={"checkboxAssign"+i} 
                          value={d.value} 
                          onClick={()=>this.handleChange(d, i)}
                          checked={d.checked || false}
                        />&nbsp;&nbsp;
                        <label className="form-check-label" for={"checkboxAssign"+i}>
                          {d.label}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
            </div>
              </InfiniteScroll>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              size="md"
              onClick={() => this.handleClose()}
            >
              Batal
            </Button>
            <Button
              variant="warning"
              size="md"
              onClick={() => this.handleFilter()}
            >
              Terapkan
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
