import React,{Fragment} from 'react';
import { Helmet } from 'react-helmet';
const KebijakanPrivasi = () => {
  return (
    <Fragment>
      <Helmet
        bodyAttributes={{
          style: "padding-top: 0!important",
        }}
      />
      <div className="d-flex w-100" style={{ height: "100vH" }}>
        <div className='container'>

            <article class="card-body">
		
            <h1 class="entry-title">Kebijakan Privasi</h1>
	
	        <div class="entry-content">
		
            <p>Mysponsor.id berkomitmen untuk selalu menjunjung tinggi keamanan dan kerahasiaan data anda
            ("<strong>Anda</strong>" atau "<strong>Pengguna</strong>") sehubungan dengan penggunaan layanan
            Kami melalui website Mysponsor. Kebijakan privasi ("<strong>Kebijakan Privasi</strong>") ini
            akan menjelaskan kebijakan Kami terkait dengan perolehan, pengumpulan,
            pengolahan, penganalisisan, penyimpanan, penampilan, pengumuman, pengiriman,
            penyebarluasan, penggunaan, pengungkapan, dan pemusnahan Data Pribadi yang Kami
            peroleh sepanjang Anda menggunakan layanan kami melalui Aplikasi.</p>



            <p>Kebijakan Privasi ini dianggap satu kesatuan dan merupakan bagian yang tidak
                 terpisahkan dengan Syarat dan Ketentuan untuk Pengguna &nbsp; ("<strong>Syarat dan Ketentuan Penggunaan</strong>").
                 Oleh karenanya istilah yang digunakan pada Kebijakan Privasi ini memiliki pengertian yang sama dengan Syarat dan Ketentuan, 
                 yang mana dapat diakses melalui website www.mysponsor.id&nbsp;, kecuali dinyatakan lain berdasarkan Kebijakan Privasi ini.</p>


            <p>Kebijakan Privasi ini
            akan diperbaharui dari waktu ke waktu sebagaimana diberitahukan kepada Pengguna
            melalui pemberitahuan pada website www.mysponsor.id . Dengan menyetujui Syarat dan Ketentuan
            atau&nbsp; dengan terus menggunakan Aplikasi
            maka Pengguna dianggap telah menyetujui dan tunduk pada kebijakan yang
            tercantum pada Kebijakan Privasi ini.</p>



            <p>Dari waktu ke waktu,
            Pengguna harus memberikan pengkinian Data Pribadi Pengguna kepada Kami
            sehubungan dengan akses dan penggunaan Aplikasi.</p>



            <h3>1. <strong>PENGUMPULAN DATA PRIBADI</strong></h3>



            <p>Untuk dapat menggunakan
            layanan Kami melalui website www.mysponsor.id, maka Anda diminta untuk menyediakan,
            menyampaikan dan/atau menyerahkan Data Pribadi Anda dengan sukarela, baik
            secara langsung maupun tidak langsung. Kami juga dapat memperoleh atau
            mengumpulkan Data Pribadi Anda pada saat Anda melalukan registrasi atau pada
            saat Anda melakukan transaksi melalui Aplikasi, apabila dibutuhkan dari waktu
            ke waktu.</p>



            <p>Kami mengumpulkan Data
            Pribadi, termasuk namun tidak terbatas pada nama, alamat, nomor telepon, alamat
            email, tanggal lahir, serta informasi yang berhubungan dengan layanan seperti
            rincian bank dan kartu kredit, jasa berlangganan yang Anda gunakan atau rincian
            lain yang berhubungan dengan akun Anda, kegiatan Anda atas Layanan Kami.</p>



            <p>Adapun selain Data
            Pribadi dan data yang disebutkan di atas, setiap Anda menggunakan Aplikasi atau
            mengunjungi Situs, kami juga mengumpulkan informasi Anda sehubungan dengan
            server domain, informasi lokasi, jenis perangkat Anda, jenis mesin pencari
            situs, sumber rujukan situs, dan informasi lain menyangkut kegiatan transaksi
            Anda pada website www.mysponsor.id.</p>



            <h3>2. <strong>PENGGUNAAN DATA PRIBADI</strong></h3>



            <p>Kami dapat menggunakan
            Data Pribadi atau informasi lain menyangkut transaksi Anda pada website www.mysponsor.id, untuk
            tujuan berikut:</p>



            <ol><li>melakukan aktivasi data Anda pada layanan yang kami sediakan melalui
            website www.mysponsor.id;</li><li>memproses pendaftaran, termasuk melakukan verifikasi kelayakan Anda dalam
            menggunakan beberapa layanan pada website www.mysponsor.id;</li><li>melaksanakan instruksi untuk membuat dan menerima pembayaran dan melakukan
            transaksi mengakses Layanan Kami, meliputi verifikasi bahwa Anda memiliki dana
            yang cukup di Deposit atau
            Saldo untuk melakukan
            pembayaran tersebut;</li><li>menyediakan informasi adanya perubahan pada layanan Kami (apabila ada);</li>
            <li>meningkatkan layanan kepada Pengguna termasuk pada penawaran kami melalui
            penelitian dan pengembangan layanan baru dari&nbsp;
            Aplikasi yang mungkin kami akan tawarkan;</li><li>memenuhi persyaratan regulasi jasa keuangan, termasuk penyimpanan informasi
            keuangan dan transaksi;</li><li>melakukan pengecekan identitas dan keuangan guna pencegahan penipuan
            dan/atau pencucian uang; </li><li>meningkatkan layanan terhadap Pengguna, meliputi menjawab pertanyaan serta
            menjawab pertanyaan dan keluhan yang Anda sampaikan kepada Anda atau orang yang
            Anda kuasakan;</li><li>tentu dengan mengindahkan persetujuan Pengguna sesuai dengan peraturan yang
            berlaku, kami dapat memberikan informasi secara langsung kepada Pengguna
            sehubungan dengan layanan yang kami tawarkan dan afiliasi kami atau pihak
            ketiga lain untuk menggunakan Data Pribadi dengan melalui telepon atau surat
            elektronik;</li><li>melakukan audit internal;</li><li>memastikan keselamatan dan keamanan dari sistem Kami; dan</li><li>memenuhi persyaratan hukum, peraturan dan persayaratan lainnya termasuk
            menyediakan bantuan terhadap penegakan hukum, peradilan, dan badan pemerintah
            lainnya.</li></ol>



            <p>Kami hanya akan
            menggunakan data Anda untuk tujuan yang kami sebutkan di atas, sebagaimana yang
            telah kami beritahukan sebelumnya terlebih dahulu, dan/atau diperbolehkan oleh
            hukum dan peraturan yang berlaku di Indonesia.</p>



            <p>Kami akan menyimpan Data
            Pribadi Anda sepanjang yang diharuskan oleh hukum dan peraturan sehubungan
            dengan Data Pribadi.</p>



            <h3><strong>3. PENGUNGKAPAN DATA PRIBADI</strong></h3>



            <p>Kami menyimpan Data
            Pribadi Anda dengan penuh kehati-hatian dan memastikan penyimpanan Data Pribadi
            sesuai dengan persyaratan berdasarkan peraturan yang berlaku.</p>





            <h3>4. Cookies</h3>



            <p>Cookies adalah
            file-file komputer kecil yang dipindahkan ke perangkat keras komputer Anda yang
            berisi informasi seperti ID, preferensi, daftar halaman yang dikunjungi dan
            aktivitas yang dilakukan ketika Anda membuka website www.mysponsor.id. </p>



            <p>Anda dapat memilih untuk
            menerima cookies atau menghapus cookies yang Kami kirimkan kepada Anda melalui
            website www.mysponsor.id. Namun, dengan menghapus cookies, Anda mungkin tidak dapat
            mengakses semua fitur atau layanan yang tersedia pada website www.mysponsor.id.</p>



            <p>Kami menggunakan cookies
            untuk meningkatkan pengalaman Anda di situs seperti melakukan penyimpanan
            password sehingga Anda tidak perlu memasukkannya kembali setiap kali Anda
            mengunjungi situs Kami.</p>



            <h3>5. Keamanan</h3>



            <p>Kami berkomitmen untuk
            memastikan bahwa informasi Anda aman. Kami memiliki prosedur fisik dan
            elektronik (internet) untuk menjaga keamanan informasi Anda, namun perlu
            diingat bahwa tidak ada metode transmisi di Internet yang dapat menjamin 100%
            aman dan terpecaya, dan kami tidak dapat menjamin keamanan yang absolut
            tersebut.</p>



            <h3>6. Tautan ke Situs Lain</h3>



            <p>Situs Kami, dari waktu ke waktu, berisi tautan ke dan dari situs pihak ketiga lain yang melakukan kerja sama, pemasang iklan dan/atau afiliasi Kami. Jika Anda mengikuti tautan ke salah satu dari situs-situs ini, mohon dicatat bahwa situs-situs ini memiliki syarat ketentuan dan kebijakan privasi sendiri dan Kami tidak memiliki tanggung jawab atau kewajiban apa pun atas kebijakan tersebut. </p>



            <p>Kami menyarankan untuk mengecek
            kebijakan-kebijakan ini sebelum mengirimkan Data Pribadi apa pun ke situs
            tersebut. </p>



            <h3>7. Perubahan Kebijakan Privasi</h3>



            <p>Kami berhak, di waktu kapan pun, untuk
            menambahkan, mengubah, mengganti, atau memodifikasi Kebijakan Privasi ini
            sehingga Anda dimohon untuk sering membaca dan memahami halaman Kebijakan
            Privasi ini. Jika Kami melakukannya, Kami akan memberi tahu Anda di halaman
            ini, membuat pos dan mengumumkannya di situs Kami, serta mencantumkan tautan ke
            kebijakan yang diubah sehingga Anda dapat membaca dan memahaminya. Dalam semua
            kasus, penggunaan informasi yang Kami kumpulkan mengacu kepada Kebijakan
            Privasi yang berlaku ketika informasi tersebut dikumpulkan.</p>



            <p>MYSPONSOR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>


        </div>

	</article>
        </div>
      </div>
    </Fragment>
  );
}

export default KebijakanPrivasi;