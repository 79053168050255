import React, { Component, Fragment } from "react";

import {Link, Redirect} from 'react-router-dom';

//table
import Filter from "./Filter";
import EmptyPage from "../../components/plugin/EmptyPage";

//// MODEL
import { deleteItem, getIndex } from "../../utils/api";

import ContentHeader from "../../components/mikro/ContentHeader";
import FileBadgeDownload from "../../components/mikro/FileBadgeDownload";

import Confirm from "../../components/plugin/ConfirmCustom";
import toastr from "../../utils/toastr";
import ReactPaginate from 'react-paginate'
import { deleteSaveUser } from "../../utils/AuthUser";

export default class Proposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Proposal",
      filter: {
        search: "",
        status: "",
      },

      items: [],
      total: 0,
      current_page: 0,
      last_page: 0,
      per_page: 0,

      isOpenPanel: false,
      deleteId: "",
      deleteTitle: "",

      redirect: "",
    };
  }
  getProposal() {
    getIndex("proposal", {
      ...this.state.filter,
      page: this.state.current_page,
    }).then((res) => {
      if(res && res.message === 'Unauthenticated.') {
        deleteSaveUser()
        this.setState({redirect: "/"});
      }
      else
        this.setState({ 
          items: res.data || '',
          total: res.meta.total || 0,
          current_page: res.meta.current_page || 0,
          last_page: res.meta.last_page || 0,
          per_page: res.meta.per_page || 0,
        });
    })
  }

  handleFilter(ft) {
    let { filter } = this.state;
    this.setState({ filter:{...filter,...ft} }, 
      () => this.getProposal()
    );
  }
  
  handleDelete() {
    deleteItem("proposal/"+this.state.deleteId).then((res) => {
      
      toastr(res.status,res.message);

      this.setState({ 
        deleteId:'',
        isOpenPanel:false,
       },()=>this.getProposal());
    });
  }

  handlePageClick = (data) => {
    const page = data.selected >= 0 ? data.selected + 1 : 0;
    this.setState(
      {
        current_page: page,
      },
      () => this.getProposal()
    );
  };

  
  handleDetail(id) {
    this.setState(
      {
        redirect: "/app/proposal/"+id,
      },
      () => this.getProposal()
    );
  }
handleClickFile(e,url){
    e.preventDefault();
    e.stopPropagation();
    window.open(url, '_blank', 'noreferrer');
  }
  render() {
    const { title, items, isOpenPanel,per_page,total,last_page,current_page,redirect } = this.state;
    if(redirect !== '')
      return <Redirect to={redirect}/>
    return (
      <Fragment>
        <ContentHeader title={title}/>
        <Filter onChangeValue={(filter) => this.handleFilter(filter)} />
        <div className="content-body">
          {parseInt(this.state.total) > 0 ? (
            <div>
              <div className="table-responsive">
                <table className="table table-hover table-hover">
                  <thead className="bg-f4">
                    <tr>
                      <th>No</th>
                      <th>Nama Kegiatan/Proposal</th>
                      <th>Tanggal Kegiatan</th>
                      <th>Lokasi</th>
                      <th>Target Peserta</th>
                      <th>File/Attachment</th>
                      <th>Follow Up</th>
                      <th>Status Publikasi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map((d, index) => (
                      <tr
                        className="pointer"
                        key={index}
                        onClick={() => this.handleDetail(d.id)}
                      >
                        <td className="align-middle">{ ((current_page - 1) * per_page) + index + 1}</td>
                        <td className="align-middle">{ d.title || '' }</td>
                        <td className="align-middle">{ d.event_date || '' }</td>
                        <td className="align-middle">{ d.event_location || '' }</td>
                        <td className="text-right align-middle">{ d.target_participants || '' }</td>
                        <td className="align-middle">
                          <FileBadgeDownload onClick={(e)=> this.handleClickFile(e,d.file_url)} />
                        </td>
                        <td className={" font-weight-bold align-middle "+(d.approval_status !== 'APPROVED' ? "text-danger " : "text-success")}>{ d.approval_status || '' }</td>
                        <td className={" font-weight-bold align-middle "+(d.status !== 'PUBLISHED' ? "text-danger " : "text-success")}>{ d.status || '' }</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="text-center w-100">
                <ReactPaginate
                  previousLabel={"Sebelumnya"}
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLabel={"Selanjutnya"}
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel={"..."}
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  marginPagesDisplayed={2}
                  onPageChange={this.handlePageClick}
                  pageRangeDisplayed={per_page}
                  pageCount={last_page}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                />
              </div>
            </div>
          ) : (
            <div className="mt-5">
              <EmptyPage text={true} />
            </div>
          )}
        </div>
        <Confirm 
          show={isOpenPanel} 
          title="Hapus Proposal"
          description={'Apakah anda ingin menghapus proposal "'+this.state.deleteTitle+'" ?'}
          btnConfirmText="Ya, Hapus"
          onChange={(value)=>value === true && this.handleDelete()}
          onClose={()=> this.setState({ 
                deleteId:'',
                isOpenPanel:false,
              })}
        />
      </Fragment>
    );
  }
}
