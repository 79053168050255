// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAEUgqNNLxMUTvHcaqIBUjOEnbyFFo5wek",
  authDomain: "mysponsor-2f232.firebaseapp.com",
  projectId: "mysponsor-2f232",
  storageBucket: "mysponsor-2f232.appspot.com",
  messagingSenderId: "54817715511",
  appId: "1:54817715511:web:d8c061a9c90d950be916b2",
  measurementId: "G-130TNL57FM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);