
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { OutletIcon } from '../../components/mikro/Icon';
import ImageIcon from './../../assets/premium-image.png'
export default class Forbidden extends Component {

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <div className="content-body">
              <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="col-md-6 col-10 p-0">
                  <div className="card-header bg-transparent border-0 text-center">
                    <img src={ImageIcon} className="img-fluid mx-2 mb-2" alt="Gambar"/>
                    <h3 
                      className="text-center"
                      style={{ lineHeight:"1.8"}}
                      >
                      Silahkan lengkapi profil event organizer Anda terlebih dahulu
                    </h3>
                  </div>
                  <div className="card-content">
                    <Link 
                      to="/app/profil-eo" 
                      className="btn btn-warning btn-block py-1 fs-16px">Lengkapi Profil
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </Fragment>
    );
  }
}

