import React, { Component, Fragment } from "react";
import "./sidebar.css";
import MenuHasSubArrow from "../../components/mikro/MenuHasSubArrow";
import { Link, Redirect } from "react-router-dom";
import {deleteSaveUser, getUser} from "../../utils/AuthUser"
import {
  DashboardIcon,
  ProdukIcon,
  LaporanIcon,
  BarangIcon,
  PelangganIcon,
  OutletIcon,
  LogOutIcon,
  UploadIcon,
} from "../../components/mikro/Icon";
import Confirm from "../../components/plugin/ConfirmCustom";
import Item from "./Item";
export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
      confirm: false,
      toLogin: false,
      user: getUser()
    };
  }

  handleOutsideClick(e) {
    if (this.props.orientation === "portrait")
      this.props.onChangeValue(!this.props.show);
  }

  handleLogout() {
    this.setState({ confirm: true });
  }

  handleConfirm(value) {
    if (value === true)
      this.setState({ toLogin: true, confirm: false }, () => deleteSaveUser());
    else this.setState({ confirm: false });
  }

  render() {
    const { menu } = this.state;

    const { orientation } = this.props;

    let show = "-300px";
    let top = "calc(4.7rem + 2px)";
    let height = "100vH";
    if (orientation === "portrait") {
      show = this.props.show ? "-300px" : "0";
      top = "0";
      height = "100vH";
    } else {
      show = this.props.show ? "0" : "-300px";
      top = "calc(4.7rem + 2px)";
      height = "calc(100vH - 4.7rem)";
    }
    return (
      <div>
        {this.state.toLogin ? <Redirect to="/" /> : ""}
        <Confirm
          title="Keluar Akun"
          description="Apakah Anda ingin keluar ?"
          btnConfirmText="Keluar"
          show={this.state.confirm}
          onClose={(e) => this.setState({ confirm:false})}
          onChange={(e) => this.handleConfirm(e)}
        />

        <div
          id="mySidebar"
          className="border-right"
          style={{
            left: show,
            top: top,
            overflow:"auto",
            height: height,
          }}
        >
          <Item
            url={this.state.url}
            title={"Beranda"}
            link="beranda"
            icon={<DashboardIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          <Item
            url={this.state.url}
            title={"Upload Proposal"}
            link="upload-proposal"
            icon={<UploadIcon  />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          <Item
            url={this.state.url}
            title={"Daftar Proposal"}
            link="daftar-proposal"
            icon={<ProdukIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          <Item
            url={this.state.url}
            title={"Profil EO"}
            link="profil-eo"
            icon={<OutletIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          
          <Item
            url={this.state.url}
            title={"Profil PIC"}
            link="profil-pic"
            icon={<BarangIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          <hr />
          <Link className="ember-view" onClick={() => this.handleLogout()}>
            <div
              className="d-flex flex-row"
              style={{ justifyContent: "flex-start" }}
            >
              <div className="icon">
                <LogOutIcon />
              </div>
              <div>Keluar</div>
            </div>
          </Link>
        </div>
        <div
          id="fill-sidebar"
          onClick={() => this.handleOutsideClick()}
          style={{
            visibility:
              orientation !== "portrait"
                ? "hidden"
                : this.props.show
                ? "hidden"
                : "visible",
          }}
        ></div>
      </div>
    );
  }
}
