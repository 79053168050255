import { toast } from 'react-toastify';
import statusCode from '../enums/statusCode'

const toastr = (status, message) => {
    if (status === statusCode.SUCCESS ) toast.success(message);
    else if (status === statusCode.WARNING) toast.warning(message);
    else if (status === statusCode.ERROR || status === statusCode.FAILED) toast.error(message);
    else toast.error("Terjadi Kesalahan");
};

export default toastr;

export const showLoadingToast = (message) => {
    toast.loading(message || "Please wait...");
};

export const hideToast = () => {
    toast.dismiss();
}