import axios from "axios";
import { getToken } from "./AuthUser";
import { URL_PATH } from "../enums/url";

const http = axios.create({
  baseURL: URL_PATH,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const getIndex = (url, filter = {}) => {
  return http
    .get(url, {
      params: filter,
      headers: {
        Authorization: getToken(),
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};

export const addItem = (url, params = {}) => {
  return http
    .post(url, params,{
    headers: {
      Authorization: getToken(),
    },
    onUploadProgress: progressEvent => {
      console.log((progressEvent.loaded / progressEvent.total) * 100)
    }
  })
    .then(({ data }) => {
      if (data.code && data.code === 422 && data.errors) {
        var error;
        for (var err of Object.keys(data.errors)) {
          error = data.errors[err][0];
        }
        return { status: data.status, message: error };
      } else return data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};

export const updateItem = (url, params = {}) => {
  return http
    .put(url, params,{
    headers: {
      Authorization: getToken(),
    }
  })
    .then(({ data }) => {
      if (data.code && data.code === 422 && data.errors) {
        var error;
        for (var err of Object.keys(data.errors)) {
          error = data.errors[err][0];
        }
        return { status: data.status, message: error };
      } else return data;
    })
    .catch((error) => {
      // Error

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};

export const deleteItem = (url, params = {}) => {
  return http
    .delete(url, {...params,
    headers: {
      Authorization: getToken(),
    }
  })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};

export const postNoAuth = (url, params = {}) => {
  return http
    .post(url, params)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};

export const getNoAuth = (url, filter = {}) => {
  return http
    .get(url, {
      params: filter,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};
