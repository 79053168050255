import { postNoAuth } from "../utils/api";
import { 
  UPDATE_RESPONSE,
} from '../enums/reducer'
import {saveUserToken} from "../utils/AuthUser";
import { hideToast, showLoadingToast } from "../utils/toastr";

export const post_login = (payload,callback) => (dispatch) =>{
    showLoadingToast()
    postNoAuth("login", payload).then((res) => {
        hideToast()
        if (res.code === 200) {
            saveUserToken(res.data.user, res.data.access_token);
            callback();
        }
        dispatch({
            type: UPDATE_RESPONSE,
            value: {
                status: res.status,
                message: res.message,
                code: res.code,
            },
        });
    }).catch((err)=>{
        hideToast()
        setTimeout(()=>{

            dispatch({
                type: UPDATE_RESPONSE,
                value: {
                    status: "ERROR",
                    message: "Tidak dapat login",
                    code: 500,
                },
            });
        },300)
    });
}


export const post_check_user_exist = (payload,callback) => (dispatch) =>{
    showLoadingToast()

    postNoAuth("password/check-user", payload).then((res) => {
        hideToast()
        if (res.code === 200) {
            callback(res);
        } else 
            dispatch({
                type: UPDATE_RESPONSE,
                value: {
                    status: res.status,
                    message: res.message,
                    code: res.code,
                },
            });
    }).catch((err)=>{
        hideToast()
        setTimeout(()=>{

            dispatch({
                type: UPDATE_RESPONSE,
                value: {
                    status: "ERROR",
                    message: "Terjadi Kesalahan",
                    code: 500,
                },
            });
        },300)
    });
}


export const post_check_mail_otp = (payload,callback) => (dispatch) =>{

    showLoadingToast()
    postNoAuth("password/check-mail-otp", payload).then((res) => {
        hideToast()
        if (res.code === 200) {
            callback(res);
        } else 
            dispatch({
                type: UPDATE_RESPONSE,
                value: {
                    status: res.status,
                    message: res.message,
                    code: res.code,
                },
            });
    }).catch((err)=>{
        hideToast()
        setTimeout(()=>{

            dispatch({
                type: UPDATE_RESPONSE,
                value: {
                    status: "ERROR",
                    message: "Terjadi Kesalahan",
                    code: 500,
                },
            });
        },300)
    });
}

export const post_set_token_reset_password = (payload,callback) => (dispatch) =>{

    showLoadingToast()
    postNoAuth("password/token", payload).then((res) => {
        hideToast()
        if (res.code === 200) {
            callback(res);
        } else 
            dispatch({
                type: UPDATE_RESPONSE,
                value: {
                    status: res.status,
                    message: res.message,
                    code: res.code,
                },
            });
    }).catch((err)=>{
        hideToast()
        setTimeout(()=>{
            dispatch({
                type: UPDATE_RESPONSE,
                value: {
                    status: "ERROR",
                    message: "Terjadi Kesalahan",
                    code: 500,
                },
            });
        },300)
    });
}