
import React, { Component, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { Link, Redirect } from 'react-router-dom';
import CardWidgetTotal from '../../components/mikro/CardWidgetTotal';
import { UploadIcon } from '../../components/mikro/Icon';
import { getIndex } from '../../utils/api';
import { deleteSaveUser } from '../../utils/AuthUser';

//// MODEL

export default class Beranda extends Component {
  constructor(props) {
    super(props)
    this.state = {
      draft: 0,
      per_month: 0,
      per_year: 0,
      published: 0,
      redirect:""
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    getIndex("widget-info").then((res) => {
      if(res && res.message === 'Unauthenticated.') {
          deleteSaveUser()
          this.setState({redirect:"/"});
        }
      if(res.code === 200)
        this.setState({
           draft: res && res.data && res.data.draft || '0',
            per_month: res && res.data && res.data.per_month || '0',
            per_year: res && res.data && res.data.per_year || '0',
            published: res && res.data && res.data.published || '0',
        })
    });
  }

  handleFilter(e) {
    
  }

  render() {
    const {redirect} = this.state
    if(redirect !== '') return <Redirect to={redirect}/>
    return (
      <Fragment>
       
        <div className="content-body">
          <div className="row mb-2">
            <CardWidgetTotal title="Total Proposal Tahun Ini" color="info" total={this.state.per_year}/>
            <CardWidgetTotal title="Total Proposal Bulan Ini" color="warning" total={this.state.per_month}/>
            <CardWidgetTotal title="Total Proposal Dipublikasikan" color="danger" total={this.state.published}/>
            <CardWidgetTotal title="Total Proposal Tersimpan" color="secondary" total={this.state.draft}/>
          </div>
          <div className='row mb-2'>
            <div className='col'>
              <div className='card border-left-blue border-right-blue'>
                <div className='card-header pb-0'>
                  <h4 className='font-weight-bold m-0'>Publikasikan Event Anda untuk Menarik Sponsor</h4>
                </div>
                <div className='card-body'>
                  <p className='fs-16px'>Isi profil event Anda dan buat prospektus digitalnya untuk menarik sponsor. Saat sponsor menemukan event Anda, mereka dapat langsung terhubung dengan Anda melalui platform. Dari sana, Anda dapat mendiskusikan penawaran event dan sponsor.</p>
                  <p className='fs-16px'>Anda bisa memulai dengan menekan tombol dibawah ini.</p>
                  <Link to="/app/upload-proposal" className="btn btn-lg btn-info">
                    <UploadIcon/> Upload Proposal Disini
                  </Link>
                </div>
              </div>
            </div>
          </div>
          

        </div>
      </Fragment>
    );
  }
}

