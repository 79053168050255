
export const getToken = () => {
  const tokenString = localStorage.getItem("t");
  const userToken = JSON.parse(tokenString);
  return userToken;
};

export const setToken = (token) => {
  localStorage.setItem("t", JSON.stringify('Bearer ' + token));
};

export const getUser = () => {
  const userString = localStorage.getItem("u");
  const userDetail = JSON.parse(userString);
  return userDetail;
};

export const setUser = (user) => {
  localStorage.setItem("u", JSON.stringify(user));
};

export const updateStatusProfilPerusahaan = () => {
  let user = getUser();
  user['status_profil_eo'] = 'ACTIVE';
  setUser(user);
}
export const updateNamaProfilPerusahaan = (nama) => {
  let user = getUser();
  user['nama_eo'] = nama;
  setUser(user);
}

export const saveUserToken = (user, token) => {
  setToken(token);
  setUser(user);
};

export const deleteSaveUser = () => {
  localStorage.clear();
};