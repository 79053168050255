import React, { useEffect, useState } from "react";
import MultiSelectList from "../plugin/form/MultiSelectList";
const KategoriMultiSelect = (props) => {
  const [show, set_show] = useState(false);

  const handleChange = (value) => {
    let dataArr = [];
    value.data.forEach(d => {
      if(d.label && d.label !== '')
        dataArr.push(d.label);
    });

    props.onChange({
      target:{
        name: props.name,
        value: dataArr
      }
    });
  }
  return (
    <>
    <div className="d-flex flex-column">
      <div>
        <button type="button" disabled={props.readOnly || false} className="btn btn-info mb-2" onClick={()=>set_show(true)}>Tandai Kategori</button>
      </div>
      <div>
        { props.value.map((kategori,i)=>
          kategori && <span 
          className="text-danger rounded text-white badge border border-danger mr-1 mb-1" 
          style={{padding:'.4rem 1rem'}}>{kategori}</span>
        )}
      </div>
    </div>
    <MultiSelectList
        url="select-categories"
        title={props.title || "Kategori"}
        openModal={show}
        onClose={() => set_show(false)}
        onChange={(value) => handleChange(value)}
      />
      </>
  );
};

export default KategoriMultiSelect;
