import React, { Component, Fragment } from "react";

import {
  DotIcon, PDFXLIcon
} from "../../components/mikro/Icon";
import ImageUploadPreview from "../../components/plugin/form/ImageUploadPreview";

import { getIndex, addItem } from "../../utils/api";
import ContentHeader from "../../components/mikro/ContentHeader";
import toastr, { hideToast, showLoadingToast } from "../../utils/toastr";

import {minLength, minNumber} from './../../utils/Validation'
import KategoriMultiSelect from "../../components/makro/KategoriMultiSelect";
import { Redirect } from "react-router-dom";
import { deleteSaveUser } from "../../utils/AuthUser";


export default class InfoKontak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:this.props.match.params.id || "",
      status: 'Draft',
      judul:'',
      tanggal_kegiatan_dimulai: '',
      tanggal_kegiatan_selesai: '',
      jam_kegiatan_dimulai: '',
      jam_kegiatan_selesai: '',
      zona_waktu_kegiatan: 'WIB',
      tempat_kegiatan:'',
      jenis_kegiatan:[],
      pesan:'',
      dukungan_yang_dibutuhkan_dari_sponsor_utama: '',
      keuntungan_menjadi_sponsor_utama: '',

      dukungan_yang_dibutuhkan_dari_sponsor_pendamping: '',
      keuntungan_menjadi_sponsor_pendamping: '',

      dukungan_yang_dibutuhkan_dari_sponsor_lainnya: '',
      keuntungan_menjadi_sponsor_lainnya: '',

      thumbnail:"",
      image:null,

      file_name:"",
      file_pdf:"",

      errors:null,
      redirect:"",
      isLoading:false,
    };
  }
  componentDidMount() {
    if(this.state.id !== '')
      this.getProfil()
  }

  getProfil(){
    getIndex("proposal/"+this.state.id+"/edit").then((res) => {
      if(res && res.message === 'Unauthenticated.') {
          deleteSaveUser()
          this.setState({rdirect:"/"});
        }
      if(res.code === 200){
        if(res.data.status === 'PUBLISHED')
          this.setState({
            redirect:'/app/daftar-proposal'
          })
        else
        this.setState({
          status: res && res.data && res.data.status,
          judul: res && res.data && res.data.title,
          tanggal_kegiatan_dimulai: res && res.data && res.data.event_date_start,
          tanggal_kegiatan_selesai: res && res.data && res.data.event_date_end,
          jam_kegiatan_dimulai: res && res.data && res.data.event_time_start,
          jam_kegiatan_selesai: res && res.data && res.data.event_time_end,
          zona_waktu_kegiatan: res && res.data && res.data.event_time_zone,
          tempat_kegiatan: res && res.data && res.data.event_location,
          target_peserta: res && res.data && res.data.target_participants,
          jenis_kegiatan:res && res.data && res.data.categories,
          pesan: res && res.data && res.data.message,

          dukungan_yang_dibutuhkan_dari_sponsor_utama: res && res.data.dukungan_yang_dibutuhkan_dari_sponsor_utama,
          keuntungan_menjadi_sponsor_utama: res && res.data.keuntungan_menjadi_sponsor_utama,

          dukungan_yang_dibutuhkan_dari_sponsor_pendamping: res && res.data.dukungan_yang_dibutuhkan_dari_sponsor_pendamping,
          keuntungan_menjadi_sponsor_pendamping: res && res.data.keuntungan_menjadi_sponsor_pendamping,

          dukungan_yang_dibutuhkan_dari_sponsor_lainnya: res && res.data.dukungan_yang_dibutuhkan_dari_sponsor_lainnya,
          keuntungan_menjadi_sponsor_lainnya: res && res.data.keuntungan_menjadi_sponsor_lainnya,
  
          file_pdf:"",
          file_name:res && res.data && res.data.file_name,
          file_url:res && res.data && res.data.file_url,
          
        });
      }
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChangeFile(e){
    if (e && e.target && e.target.files && e.target.files[0]) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.setState({ 
        file_name: files[0].name,
        file_pdf: files[0]
      })
    }
  }


  handleSubmit(status) {

    let errors = null
    const {
      file_pdf,
      judul,
      tanggal_kegiatan_dimulai,
      tanggal_kegiatan_selesai,
      jam_kegiatan_dimulai,
      jam_kegiatan_selesai,
      zona_waktu_kegiatan,
      tempat_kegiatan,
      target_peserta,
      jenis_kegiatan,
      pesan,

      dukungan_yang_dibutuhkan_dari_sponsor_utama,
      keuntungan_menjadi_sponsor_utama,

      dukungan_yang_dibutuhkan_dari_sponsor_pendamping,
      keuntungan_menjadi_sponsor_pendamping,

      dukungan_yang_dibutuhkan_dari_sponsor_lainnya,
      keuntungan_menjadi_sponsor_lainnya,

    } = this.state;
    if (!file_pdf && this.state.id === '')  errors = {...errors, proposal : "Proposal harus terisi"};
    if (!minLength(judul,5))  errors = {...errors, judul : "Judul minimal 5 karakter"}
    if (!minLength(tanggal_kegiatan_dimulai,8))  errors = {...errors, tanggal_kegiatan_dimulai : "Tanggal kegiatan dimulai tidak boleh kosong"}
    if (!minLength(tanggal_kegiatan_selesai,8))  errors = {...errors, tanggal_kegiatan_selesai : "Tanggal kegiatan selesai tidak boleh kosong"}
    if (!minLength(jam_kegiatan_dimulai,1))  errors = {...errors, jam_kegiatan_dimulai : "Jam kegiatan dimulai tidak boleh kosong"}
    if (!minLength(jam_kegiatan_selesai,1))  errors = {...errors, jam_kegiatan_selesai : "Jam kegiatan selesai tidak boleh kosong"}
    if (!minLength(zona_waktu_kegiatan,1))  errors = {...errors, zona_waktu_kegiatan : "Zona waktu tidak boleh kosong"}
   
    if (!minLength(tempat_kegiatan,1))  errors = {...errors, tempat_kegiatan : "Tempat kegiatan tidak boleh kosong"}
    if (!minNumber(target_peserta,5))  errors = {...errors, target_peserta : "Target peserta minimal 5"}
    if (jenis_kegiatan.length === 0)  errors = {...errors, jenis_kegiatan : "Kategori minimal 1"};
    
    if (!minLength(dukungan_yang_dibutuhkan_dari_sponsor_utama,1))  errors = {...errors, dukungan_yang_dibutuhkan_dari_sponsor_utama : "Dukungan yang dibutuhkan dari sponsor utama tidak boleh kosong"};
    if (!minLength(keuntungan_menjadi_sponsor_utama,1))  errors = {...errors, keuntungan_menjadi_sponsor_utama : "Keuntungan menjadi sponsor utama tidak boleh kosong"};
    if (!minLength(dukungan_yang_dibutuhkan_dari_sponsor_pendamping,1))  errors = {...errors, dukungan_yang_dibutuhkan_dari_sponsor_pendamping : "Dukungan yang dibutuhkan dari sponsor pendamping tidak boleh kosong"};
    if (!minLength(keuntungan_menjadi_sponsor_pendamping,1))  errors = {...errors, keuntungan_menjadi_sponsor_pendamping : "Keuntungan menjadi sponsor pendamping tidak boleh kosong"};

    if (!minLength(pesan,1))  errors = {...errors, pesan : "Pesan tidak boleh kosong"};

    this.setState({ errors })

    const data = new FormData() 
    if(file_pdf)
      data.append('pdf', file_pdf)

    if (this.state.id !== '')
      data.append('_method', "put")

    data.append('judul', judul);
    data.append('tanggal_kegiatan_dimulai', tanggal_kegiatan_dimulai);
    data.append('tanggal_kegiatan_selesai', tanggal_kegiatan_selesai);
    data.append('jam_kegiatan_dimulai', jam_kegiatan_dimulai);
    data.append('jam_kegiatan_selesai', jam_kegiatan_selesai);
    data.append('zona_waktu_kegiatan', zona_waktu_kegiatan);
    data.append('tempat_kegiatan', tempat_kegiatan);
    data.append('target_peserta', target_peserta);
    data.append('pesan', pesan);
    data.append('status', status);
    
    data.append('dukungan_yang_dibutuhkan_dari_sponsor_utama', dukungan_yang_dibutuhkan_dari_sponsor_utama);
    data.append('keuntungan_menjadi_sponsor_utama', keuntungan_menjadi_sponsor_utama);

    data.append('dukungan_yang_dibutuhkan_dari_sponsor_pendamping', dukungan_yang_dibutuhkan_dari_sponsor_pendamping);
    data.append('keuntungan_menjadi_sponsor_pendamping', keuntungan_menjadi_sponsor_pendamping);

    data.append('dukungan_yang_dibutuhkan_dari_sponsor_lainnya', dukungan_yang_dibutuhkan_dari_sponsor_lainnya);
    data.append('keuntungan_menjadi_sponsor_lainnya', keuntungan_menjadi_sponsor_lainnya);
    
    for (var i = 0; i < jenis_kegiatan.length; i++) {
      data.append('jenis_kegiatan[]', jenis_kegiatan[i]);
    }

    if (errors === null){
      this.setState({
        isLoading: true
      })
      showLoadingToast("Uploading, Please Wait...")
      addItem("proposal"+(this.state.id !== '' ? '/'+this.state.id : ''), data).then((res) => {
        hideToast()
        toastr(res.status, res.message);
        this.setState({
          isLoading: false
        })
        if(res.code === 200)
          this.setState({ 
            id:"",
            status: 'Draft',
            judul:'',
            tanggal_kegiatan_dimulai: '',
            tanggal_kegiatan_selesai: '',
            jam_kegiatan_dimulai: '',
            jam_kegiatan_selesai: '',
            zona_waktu_kegiatan: '',
            tempat_kegiatan:'',
            jenis_kegiatan:[],
            pesan:'',
            dukungan_yang_dibutuhkan_dari_sponsor_utama: '',
            keuntungan_menjadi_sponsor_utama: '',

            dukungan_yang_dibutuhkan_dari_sponsor_pendamping: '',
            keuntungan_menjadi_sponsor_pendamping: '',

            dukungan_yang_dibutuhkan_dari_sponsor_lainnya: '',
            keuntungan_menjadi_sponsor_lainnya: '',

            thumbnail:"",
            image:null,

            file_name:"",
            file_pdf:"",

            errors:null,
            redirect:'/app/daftar-proposal',
            isLoading:false,
          })
      }).catch((err)=>{
        hideToast()
        this.setState({
          isLoading: false
        })
      });
    }
  }

  render() {
    const {
      status,
      judul,
      tanggal_kegiatan_dimulai,
      tanggal_kegiatan_selesai,
      jam_kegiatan_dimulai,
      jam_kegiatan_selesai,
      zona_waktu_kegiatan,
      tempat_kegiatan,
      target_peserta,
      jenis_kegiatan,

      dukungan_yang_dibutuhkan_dari_sponsor_utama,
      keuntungan_menjadi_sponsor_utama,

      dukungan_yang_dibutuhkan_dari_sponsor_pendamping,
      keuntungan_menjadi_sponsor_pendamping,

      dukungan_yang_dibutuhkan_dari_sponsor_lainnya,
      keuntungan_menjadi_sponsor_lainnya,

      pesan,
      file_name,
      file_url,
      errors,
      redirect,
      isLoading
    } = this.state;
    if(redirect !== "") return <Redirect to={redirect}/>;
    return (
      <Fragment>
        <ContentHeader title={"Upload Proposal"}/>
        
        <div className="content-body">
          <form onSubmit={(e) => null} className="form form-horizontal striped-rows">
            <div className="card">
	            <div className="card-header border-bottom d-flex justify-content-between">
	               <h4>Upload Proposal</h4>
	               <div className="font-weight-bold text-danger fs-16px d-flex align-items-center">
                    <DotIcon width="24" height="24"/> <span>{status}</span>
                 </div>
	            </div>
	            <div className="card-content collapse show">
	              <div className="card-body px-0">
                  <div className="form-body">
                    {file_name && file_url && <div className="form-group row mx-auto ">
                      <label className="col-lg-3 label-control">File Proposal*</label>
                      <div className="col-lg-3 pr-1 d-flex flex-column">
                        <a href={file_url} className=" card p-1 d-flex flex-row align-items-center">
                          <div className="mr-05">
                            <PDFXLIcon width="50" height="50" />
                          </div>
                          <div className="">
                            {file_name || ''}
                          </div>
                        </a>
                      </div>
                    </div>}
                    <div className="form-group row mx-auto ">
                      <label className="col-lg-3 label-control d-flex flex-column">Upload Proposal*</label>
                      <div className="col-lg-8 pr-1">
                        <div class="custom-file">
                          <input 
                            type="file" 
                            class="custom-file-input" 
                            id="customFile" 
                            accept="application/pdf" 
                            onChange={(e) => this.handleChangeFile(e)}
                            readOnly={isLoading}
                            required/>
                          <label class="custom-file-label" for="customFile">{ file_name || 'Upload File'} </label>
                        </div>
                        <small className="mt-1 text-muted">&#128712;&nbsp;Upload file dalam format .pdf</small>
                        {errors && errors.proposal && <p><span className="text-danger">{errors.proposal}</span></p>}
                      </div>
                    </div>
                    <div className="form-group row mx-auto align-items-center">
                      <label className="col-lg-3 text-right" >Judul Proposal*</label>
                      <div className="col-lg-8 pr-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan judul proposal"
                          value={judul}
                          name="judul" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required/>
                          {errors && errors.judul && <p><span className="text-danger">{errors.judul}</span></p>}
                      </div>
                    </div>
                    <div className="form-group row mx-auto align-items-center">
                      <label className="col-lg-3 text-right" >Tanggal Kegiatan*</label>
                      <div className="col-lg-4 pr-1">
                        <label className=" font-weight-bold">Dimulai</label>
                        <input 
                          type="date" 
                          className="form-control" 
                          placeholder="Masukkan tanggal berlaku" 
                          value={tanggal_kegiatan_dimulai} 
                          name="tanggal_kegiatan_dimulai" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required/>
                          {errors && errors.tanggal_kegiatan_dimulai && <p><span className="text-danger">{errors.tanggal_kegiatan_dimulai}</span></p>}
                      </div>
                      <div className="col-lg-4 pr-1">
                        <label className=" font-weight-bold">Selesai</label>
                        <input 
                          type="date" 
                          className="form-control" 
                          placeholder="Masukkan tanggal berlaku" 
                          value={tanggal_kegiatan_selesai} 
                          name="tanggal_kegiatan_selesai" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required/>
                          {errors && errors.tanggal_kegiatan_selesai && <p><span className="text-danger">{errors.tanggal_kegiatan_selesai}</span></p>}
                      </div>
                    </div>
                    <div className="form-group row mx-auto align-items-center">
                      <label className="col-lg-3 text-right" >Jam Kegiatan*</label>
                      <div className="col-lg-8">
                        <div className="row mb-1">
                          <div className="col-lg-6 pr-1">
                            <label className=" font-weight-bold">Dimulai</label>
                            <input 
                              type="time" 
                              className="form-control" 
                              placeholder="Masukkan tanggal berlaku" 
                              value={jam_kegiatan_dimulai} 
                              name="jam_kegiatan_dimulai" 
                              onChange={(e)=>this.handleChange(e)}
                                readOnly={isLoading}
                              required/>
                              {errors && errors.jam_kegiatan_dimulai && <p><span className="text-danger">{errors.jam_kegiatan_dimulai}</span></p>}
                          </div>
                          <div className="col-lg-6 pr-1">
                            <label className=" font-weight-bold">Selesai</label>
                            <input 
                              type="time" 
                              className="form-control" 
                              placeholder="Masukkan tanggal berlaku" 
                              value={jam_kegiatan_selesai} 
                              name="jam_kegiatan_selesai" 
                              onChange={(e)=>this.handleChange(e)}
                                readOnly={isLoading}
                              required/>
                              {errors && errors.jam_kegiatan_selesai && <p><span className="text-danger">{errors.jam_kegiatan_selesai}</span></p>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 pr-1">
                            <label className=" font-weight-bold">Zona Waktu Kegiatan</label>
                            <select 
                              className="form-control" 
                              value={zona_waktu_kegiatan} 
                              name="zona_waktu_kegiatan" 
                              onChange={(e)=>this.handleChange(e)}
                              readOnly={isLoading}
                              required>
                                <option value="WIB">WIB (Waktu Indonesia Barat)</option>
                                <option value="WITA">WITA (Waktu Indonesia Tengah)</option>
                                <option value="WIT">WIT (Waktu Indonesia Timur)</option>
                            </select>
                            {errors && errors.zona_waktu_kegiatan && <p><span className="text-danger">{errors.zona_waktu_kegiatan}</span></p>}
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div className="form-group row mx-auto align-items-center">
                      <label className="col-lg-3 text-right" >Tempat Kegiatan*</label>
                      <div className="col-lg-8 pr-1">
                        <input 
                          type="text" 
                          className="form-control" 
                          placeholder="Masukkan tempat kegiatan" 
                          value={tempat_kegiatan} 
                          name="tempat_kegiatan" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required/>
                          {errors && errors.tempat_kegiatan && <p><span className="text-danger">{errors.tempat_kegiatan}</span></p>}
                      </div>
                    </div>
                    <div className="form-group row mx-auto align-items-center">
                      <label className="col-lg-3 text-right" >Target Peserta*</label>
                      <div className="col-lg-8 pr-1">
                        <input 
                          type="number" 
                          className="form-control" 
                          placeholder="Masukkan target peserta " 
                          value={target_peserta} 
                          name="target_peserta" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                            min="1"
                          required/>
                          {errors && errors.target_peserta && <p><span className="text-danger">{errors.target_peserta}</span></p>}
                      </div>
                    </div>
                    <div className="form-group row mx-auto align-items-center">
                      <label className="col-lg-3 text-right" >Jenis Kegiatan*</label>
                      <div className="col-lg-8 pr-1">
                        <KategoriMultiSelect title="Jenis Kegiatan" readOnly={isLoading} name="jenis_kegiatan" value={jenis_kegiatan || []} onChange={(e) => this.handleChange(e)}/>
                        {errors && errors.jenis_kegiatan && <p><span className="text-danger">{errors.jenis_kegiatan}</span></p>}
                      </div>
                    </div>
                    <div className="form-group row mx-auto align-items-center">
                      <label className="col-lg-3 text-right" >Deskripsi / Pesan ke sponsor</label>
                      <div className="col-lg-8 pr-1">
                        <textarea
                          className="form-control"
                          placeholder="Masukkan pesan singkat"
                          rows={3}
                          value={pesan}
                          name="pesan" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required>
                         </textarea>
                        {errors && errors.pesan && <p><span className="text-danger">{errors.pesan}</span></p>}
                      </div>
                    </div>
                    <div className="form-group row mx-auto align-items-center">
                      <label className="col-lg-3 text-right" >
                        <small>(Support Sponsor)</small><br/>Sponsor Utama*
                      </label>
                      <div className="col-lg-8 pr-1">
                        <label className="text-right font-weight-bold" >Dukungan yang dibutuhkan:</label>
                        <textarea
                          className="form-control"
                          placeholder="Masukkan deskripsi dukungan yang dibutuhkan"
                          rows={2}
                          value={dukungan_yang_dibutuhkan_dari_sponsor_utama}
                          name="dukungan_yang_dibutuhkan_dari_sponsor_utama" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required>
                        </textarea>
                        {errors && errors.dukungan_yang_dibutuhkan_dari_sponsor_utama && <p><span className="text-danger">{errors.dukungan_yang_dibutuhkan_dari_sponsor_utama}</span></p>}
                        <br/>
                        <label className="text-right font-weight-bold" >Keuntungan menjadi Sponsor Utama:</label>
                        <textarea
                          className="form-control"
                          placeholder="Masukkan deksripsi keuntungan menjadi sponsor utama"
                          rows={2}
                          value={keuntungan_menjadi_sponsor_utama}
                          name="keuntungan_menjadi_sponsor_utama" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required>
                        </textarea>
                        {errors && errors.keuntungan_menjadi_sponsor_utama && <p><span className="text-danger">{errors.keuntungan_menjadi_sponsor_utama}</span></p>}
                      </div>
                    </div>

                    <div className="form-group row mx-auto align-items-center">
                      <label className="col-lg-3 text-right" >
                        <small>(Support Sponsor)</small><br/>Sponsor Pendamping*
                      </label>
                      <div className="col-lg-8 pr-1">
                        <label className="text-right font-weight-bold" >Dukungan yang dibutuhkan:</label>
                        <textarea
                          className="form-control"
                          placeholder="Masukkan deskripsi dukungan yang dibutuhkan"
                          rows={2}
                          value={dukungan_yang_dibutuhkan_dari_sponsor_pendamping}
                          name="dukungan_yang_dibutuhkan_dari_sponsor_pendamping" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required>
                        </textarea>
                        {errors && errors.dukungan_yang_dibutuhkan_dari_sponsor_pendamping && <p><span className="text-danger">{errors.dukungan_yang_dibutuhkan_dari_sponsor_pendamping}</span></p>}
                        <br/>
                        <label className="text-right font-weight-bold" >Keuntungan menjadi Sponsor Pendamping:</label>
                        <textarea
                          className="form-control"
                          placeholder="Masukkan deksripsi keuntungan menjadi sponsor pendamping"
                          rows={2}
                          value={keuntungan_menjadi_sponsor_pendamping}
                          name="keuntungan_menjadi_sponsor_pendamping" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required>
                        </textarea>
                        {errors && errors.keuntungan_menjadi_sponsor_pendamping && <p><span className="text-danger">{errors.keuntungan_menjadi_sponsor_pendamping}</span></p>}
                      </div>
                    </div>

                    <div className="form-group row mx-auto align-items-center">
                      <label className="col-lg-3 text-right" >
                        <small>(Support Sponsor)</small><br/>Sponsor Lainnya
                      </label>
                      <div className="col-lg-8 pr-1">
                        <label className="text-right font-weight-bold" >Dukungan yang dibutuhkan:</label>
                        <textarea
                          className="form-control"
                          placeholder="Masukkan deskripsi dukungan yang dibutuhkan"
                          rows={2}
                          value={dukungan_yang_dibutuhkan_dari_sponsor_lainnya}
                          name="dukungan_yang_dibutuhkan_dari_sponsor_lainnya" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required>
                        </textarea>
                        {errors && errors.dukungan_yang_dibutuhkan_dari_sponsor_lainnya && <p><span className="text-danger">{errors.dukungan_yang_dibutuhkan_dari_sponsor_lainnya}</span></p>}
                        <br/>
                        <label className="text-right font-weight-bold" >Keuntungan menjadi Sponsor Lainnya:</label>
                        <textarea
                          className="form-control"
                          placeholder="Masukkan deksripsi keuntungan menjadi sponsor lainnya"
                          rows={2}
                          value={keuntungan_menjadi_sponsor_lainnya}
                          name="keuntungan_menjadi_sponsor_lainnya" 
                          onChange={(e)=>this.handleChange(e)}
                            readOnly={isLoading}
                          required>
                        </textarea>
                        {errors && errors.keuntungan_menjadi_sponsor_lainnya && <p><span className="text-danger">{errors.keuntungan_menjadi_sponsor_lainnya}</span></p>}
                      </div>
                    </div>
                    
                  </div>
                  <div className="card-footer px-0 d-flex justify-content-center ">
                    <div 
                      className="btn btn-outline-secondary py-1 mr-2" 
                      onClick={()=>!isLoading && this.handleSubmit('DRAFT')} 
                      >Simpan ke Draf</div>
                    <div 
                      className="btn btn-info py-1  mr-2" 
                      style={{ minWidth:'30%'}} 
                      onClick={()=>!isLoading && this.handleSubmit('PUBLISHED')}>Kirim ke Sponsor</div>
                  </div>
                </div>
	            </div>
            </div>
            
          </form>
        </div>
      </Fragment>
    );
  }
}
