import React, { Component, Fragment } from "react";

import ImageUploadPreview from "../../components/plugin/form/ImageUploadPreview";

import { getIndex, addItem } from "../../utils/api";
import ContentHeader from "../../components/mikro/ContentHeader";
import toastr from "../../utils/toastr";

import {minLength} from './../../utils/Validation'
import WilayaSelect from "../../components/makro/WilayahSelect";
import { updateStatusProfilPerusahaan, updateNamaProfilPerusahaan, deleteSaveUser } from "../../utils/AuthUser";
import { Redirect } from "react-router-dom";


export default class ProfilPerusahaan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eo_name:'',
      eo_telephone:'',
      eo_email:'',
      eo_address:'',
      eo_website:'',
      eo_operation_id:'',
      eo_operation_label:'',

      eo_logo:"",
      image:null,
      errors:null,
      isLoading:false,
      isSaving:false,
      redirect:"",
    };
  }
  componentDidMount() {
    this.getProfil()
  }

  getProfil(){
    this.setState({ isLoading:true },()=>{
      getIndex("eo-profile").then((res) => {

        if(res && res.message === 'Unauthenticated.') {
          deleteSaveUser()
          this.setState({redirect:"/"});
        }
        this.setState({
          eo_logo: res && res.data &&( res.data.eo_logo || ''),
          eo_name: res && res.data &&( res.data.eo_name || ''),
          eo_telephone: res && res.data &&( res.data.eo_telephone || ''),
          eo_email: res && res.data &&( res.data.eo_email || ''),
          eo_address: res && res.data &&( res.data.eo_address || ''),
          eo_website: res && res.data &&( res.data.eo_website || ''),
          eo_operation_id: res && res.data &&( res.data.eo_operation_id || ''),
          eo_operation_label: res && res.data &&( res.data.eo_operation_label || ''),
          isLoading: false,
          isSaving: false
        });
      });
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let errors = null
    const {
      eo_name,
      eo_telephone,
      eo_email,
      eo_address,
      eo_website,
      eo_operation_id,
    } = this.state
    if (!minLength(eo_name,5))  errors = {...errors,eo_name : "Nama event organizer minimal 2 karakter"}
    if (!minLength(eo_telephone,8))  errors = {...errors,eo_telephone : "Nomor telpon event organizer minimal 3 karakter"}
    if (!minLength(eo_email,5))  errors = {...errors,eo_email : "Email event organizer minimal 5 karakter"}
    if (!minLength(eo_address,3))  errors = {...errors,eo_address : "Alamat event organizer minimal 3 karakter"}

    this.setState({ errors })

    const data = new FormData() 
    data.append('eo_logo', this.state.image)

    data.append('eo_name',eo_name);
    data.append('eo_telephone',eo_telephone);
    data.append('eo_email',eo_email);
    data.append('eo_address',eo_address);
    data.append('eo_website',eo_website);
    data.append('eo_operation_id',eo_operation_id);

    if (errors === null)
    this.setState({ isSaving:true },()=>{
      addItem("eo-profile", data).then((res) => {
        setTimeout(()=>{
          this.setState({ isSaving: false})

        },1000)
        toastr(res.status, res.message);
        updateNamaProfilPerusahaan(eo_name);
        updateStatusProfilPerusahaan();
      });
    })
  }

  render() {
    const {
      eo_name,
      eo_telephone,
      eo_email,
      eo_address,
      eo_operation_label,
      errors,
      isLoading,
      isSaving,
      redirect
    } = this.state;
    if(redirect !== '') return <Redirect to={redirect}/>
    return (
      <Fragment>
        <ContentHeader
          title="Profil EO"
        />
        <div className="content-body">
          <form onSubmit={(e) => this.handleSubmit(e)}>
           
            <div className="row justify-content-center p-1">
              <div className="col-10  mb-1">
                <div className="mb-3 text-center">
                  <ImageUploadPreview
                    value={this.state.eo_logo}
                    height="150px"
                    width="170px"
                    onChangeValue={(value) => this.setState({ image: value })}
                  />
                  <small className="mt-1 ">&#128712;&nbsp;Upload Logo Disini</small>
                </div>
                <div className="row justify-content-center mb-2">
                  <div className="col-md-10">
                    <label>Nama event organizer*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Masukkan nama event organizer"
                      name="eo_name"
                      value={eo_name}
                      onChange={(e) => this.handleChange(e)}
                      disabled={isLoading || isSaving}
                    />
                    {errors && errors.nama_pic && (
                      <div className="invalid-feedback d-block">
                        {errors.nama_pic}
                      </div>
                    )}
                  </div>
                  
                </div>
                <div className="row justify-content-center mb-2">
                  <div className="col-md-5">
                    <label>Nomor Telpon*</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">+62</span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Masukkan nomor telpon"
                        name="eo_telephone"
                        value={eo_telephone}
                        onChange={(e) => this.handleChange(e)}
                        required={true}
                      />
                    </div>
                    {errors && errors.nama_pic && (
                      <div className="invalid-feedback d-block">
                        {errors.nama_pic}
                      </div>
                    )}
                  </div>
                  
                  <div className="col-md-5">
                    <label>Email*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Masukkan email"
                      name="eo_email"
                      value={eo_email}
                      onChange={(e) => this.handleChange(e)}
                      required={true}
                      disabled={isLoading || isSaving}
                    />
                    {errors && errors.nama_pic && (
                      <div className="invalid-feedback d-block">
                        {errors.nama_pic}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row justify-content-center mb-2">
                  <div className="col-md-10">
                    <label>Wilayah Operasional</label>
                    <WilayaSelect 
                      placeholder="Pilih wilayah operasional"
                      name="eo_operation_id"
                      defaultShowedValue={eo_operation_label}
                      onChange={(e) => this.handleChange(e)}/>
                  </div>
                </div>
                <div className="row justify-content-center mb-2">
                  <div className="col-md-10">
                    <label>Alamat*</label>
                    <textarea
                      className="form-control"
                      placeholder="Masukkan alamat event organizer"
                      name="eo_address"
                      value={eo_address}
                      onChange={(e) => this.handleChange(e)}
                      required={true}
                      disabled={isLoading || isSaving}
                    ></textarea>
                    {errors && errors.eo_address && (
                      <div className="invalid-feedback d-block">
                        {errors.eo_address}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
           

            <div className="row p-1">
              <div className="col-12">
                <div className="form-group text-center">
                  <button 
                    type="submit" 
                    className="btn btn-info px-5 py-1" 
                    style={{ minWidth:'40%'}}
                    disabled={isLoading || isSaving}
                  >
                    {isSaving ? "Menyimpan...":"Simpan"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}
